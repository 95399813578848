a.link {
  font-size: 0.97em;
  text-decoration: none;
  font-weight: bold;
  color: #000000;
}

a:hover.link {
  text-decoration: underline;
}

a:visited.link {
  color: #000000;
}
